<template>
  <div>
    <div class="fx-row mt16 bac-fff fx-1">
      <!-- 资源分组 -->
      <div class="resource-grouping">
        <el-card class="lf-true" :body-style="{ width: '232px' }">
          <div slot="header" class="fx-sw-cen">
            <div class="tit-mid">课程分组</div>
          </div>
          <div>
            <el-tree :data="data">
              <div
                style="
                  flex: 1;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
                slot-scope="{ node, data }"
              >
                <span>{{ node.label }}</span>
              </div>
            </el-tree>
          </div>
        </el-card>
      </div>
      <!-- 列表 -->
      <div class="w200 fx-1">
        <div class="container-warp">
          <div class="course-item" @click="courseDetail">
            <div class="img-box">
              <img src="@/assets/img/login-bg.jpg" alt="" />
              <div class="pitch-box">共4节</div>
              <div class="state-box">已下架</div>
            </div>
            <div class="bottom-box">
              <div class="name-box">
                <div>课程名称</div>
                <div>入职培训</div>
              </div>
              <div class="progress-box">
                <div style="margin-right: 10px">已学10%</div>
                <el-progress :percentage="50" :show-text="false"></el-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tableData: [{ name: '111' }, { name: '111' }],
      data: [
        {
          label: '一级 1',
          children: [
            {
              label: '二级 1-1',
              children: [
                {
                  label: '三级 1-1-1'
                }
              ]
            }
          ]
        },
        {
          label: '一级 2',
          children: [
            {
              label: '二级 2-1',
              children: [
                {
                  label: '三级 2-1-1'
                }
              ]
            },
            {
              label: '二级 2-2',
              children: [
                {
                  label: '三级 2-2-1'
                }
              ]
            }
          ]
        },
        {
          label: '一级 3',
          children: [
            {
              label: '二级 3-1',
              children: [
                {
                  label: '三级 3-1-1'
                }
              ]
            },
            {
              label: '二级 3-2',
              children: [
                {
                  label: '三级 3-2-1'
                }
              ]
            }
          ]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    handleNodeClick () {},
    courseDetail () {
      this.$router.push({
        path: '/courseDetail'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-warp {
  padding: 24px 0 0 24px;
  min-height: 800px;
  background-color: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
}
.course-item {
  margin: 0 24px 24px 0;
  width: 208px;
  height: 226px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px rgba(17, 44, 69, 0.02),
    0px 8px 28px 0px rgba(37, 73, 114, 0.05);
  .img-box {
    position: relative;
    width: 100%;
    height: 156px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
    .pitch-box {
      position: absolute;
      top: 0;
      left: 0;
      padding: 3px 12px;
      font-size: 12px;
      color: #0089ff;
      background-color: #ebf6ff;
      border-radius: 4px;
    }
    .state-box {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 3px 12px;
      font-size: 12px;
      color: #4d4d4d;
      background-color: #d9d9d9;
      border-radius: 4px;
    }
  }
  .bottom-box {
    padding: 8px 12px 10px;
    .name-box {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      div {
        &:first-child {
          margin-right: 16px;
          font-size: 16px;
          font-weight: 600;
          color: #333333;
        }
        &:last-child {
          padding: 3px 12px;
          font-size: 12px;
          color: #ffffff;
          border-radius: 8px 0px 8px 0px;
          background: linear-gradient(to right, #31adf6, #5096ff);
        }
      }
    }
    .progress-box {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;
      /deep/.el-progress {
        width: 118px;
      }
    }
  }
}
</style>
